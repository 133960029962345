import { computed, inject, Injectable, signal } from '@angular/core';
import { MemberDto, MemberLoginDto, MemberService } from '@api-client';
import { Preferences } from '@capacitor/preferences';
import { ModalController } from '@ionic/angular/standalone';
import { lastValueFrom, tap } from 'rxjs';
import { AdditionalInfoModal } from '../modals/additional-info/additional-info.modal';
import { MEMBER_ACCESS_TOKEN_KEY, MEMBER_REFRESH_TOKEN_KEY } from '@common';

@Injectable({ providedIn: 'root' })
export class AuthStore {
  member = signal<MemberDto | null>(null);
  logined = computed(() => !!this.member());

  additionalInfoRequired = computed(() => {
    const member = this.member();

    if (!member) return false;

    function isValid(value?: string | null) {
      return value && value.length > 0 && value !== 'null' && value !== 'undefined' && value !== undefined && value !== null;
    }

    return [member.name, member.tel, member.email].some((value) => !isValid(value));
  });

  readonly memberService = inject(MemberService);
  readonly modalController = inject(ModalController);

  constructor() {
    this.fetchMember();
  }

  async fetchMember() {
    return new Promise<MemberDto | null>((resolve) => {
      this.memberService.memberControllerGetMe().subscribe({
        next: (member) => {
          if (member) {
            this.member.set(member);

            if ('accessToken' in member) {
              Preferences.set({ key: MEMBER_ACCESS_TOKEN_KEY, value: member.accessToken });
            }

            // if (this.additionalInfoRequired()) {
            //   this.modalController
            //     .create({
            //       component: AdditionalInfoModal,
            //       componentProps: {
            //         name: this.member()?.name,
            //         email: this.member()?.email,
            //         tel: this.member()?.tel,
            //       },
            //       keyboardClose: false,
            //     })
            //     .then((modal) => setTimeout(() => modal.present(), 500));
            // }

            resolve(member);
          } else {
            resolve(null);
          }
        },
      });
    });
  }

  async login(body: MemberLoginDto) {
    const { accessToken, refreshToken } = await lastValueFrom(this.memberService.memberControllerLogin({ body }));

    await Preferences.set({ key: MEMBER_ACCESS_TOKEN_KEY, value: accessToken });
    await Preferences.set({ key: MEMBER_REFRESH_TOKEN_KEY, value: refreshToken });

    const member = await this.fetchMember();

    if (member) this.member.set(member);
  }

  logout() {
    return this.memberService.memberControllerLogout().pipe(
      tap(async () => {
        await Preferences.remove({ key: MEMBER_ACCESS_TOKEN_KEY });
        await Preferences.remove({ key: MEMBER_REFRESH_TOKEN_KEY });
        this.member.set(null);
      })
    );
  }

  async resetMemberData() {
    await Preferences.remove({ key: MEMBER_ACCESS_TOKEN_KEY });
    await Preferences.remove({ key: MEMBER_REFRESH_TOKEN_KEY });
    this.member.set(null);
  }
}
